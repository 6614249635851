import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Slide, Typography, useTheme } from '@mui/material';

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = forwardRef(({ children, content, variant, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, ...others }, ref) => {

  const theme = useTheme();
  return (
    <Slide in={true} direction="up" timeout={500}>
      <Card
        ref={ref}
        sx={{
          background: `${theme.palette.background.default}50`,
          borderRadius: '15px',
          border: '1px solid transparent',
          ...sx
        }}
        {...others}
      >
        {/* card header and action */}
        {!darkTitle && title && <CardHeader sx={{ p: 2.5 }} title={<Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">{title}</Typography>} action={secondary} />}
        {darkTitle && title && <CardHeader sx={{ p: 2.5 }} title={<Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">{title}</Typography>} action={secondary} />}

        {/* content & header divider */}
        {title && (
          <Divider
            sx={{
              opacity: 1,
            }}
          />
        )}

        {/* card content */}
        {content && (
          <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    </Slide>
  );
});

SubCard.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  contentSX: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

SubCard.defaultProps = {
  content: true
};

export default SubCard;
