import { getServerTimestampNow } from "database/repositories/SystemRepo";
import { PaymentMethods } from "values/Includes";

import { getCostShutterTotal, getQuotationShutterTotal, isShutterQuotationValid } from "model/order/quotation/shutter";
import { getCostAccessoryTotal, getQuotationAccessoriesTotal, isAccessoriesQuotationValid } from "./accessory";
import { getCostBlindsTotal, getQuotationBlindsTotal } from 'model/order/quotation/blinds';
import { getCostSectorTotalPrice, getQuotationSectorsTotal } from "model/order/quotation/sector";
import { getCostBoxesTotal, getQuotationBoxesTotal } from "model/order/quotation/box";
import { getCostBoxSidesTotal, getQuotationBoxSidesTotal } from "model/order/quotation/boxSide";
import { getCostTracksTotal, getQuotationTracksTotal } from "model/order/quotation/track";
import { getCostMotorsTotal, getQuotationMotorsTotal } from "./motor";
import { getLaborTotal } from "model/Labor";

export const initQuotation = () => {
  var quotation = {
    note: "",
    discount: 0,
    transportation: 0,
    cost: {
      transportation: 0,
    }
  }

  return quotation;
}

export const deleteQuotationProperty = (quotation, property) => {
  const updatedQuotation = { ...quotation };
  delete updatedQuotation[property];
  return updatedQuotation;
};

export const addQuotationProperty = (quotation, property) => {
  return {
    ...quotation,
    [property]: []
  };
};

export const getQuotationTranportation = (quotation) => {
  var transportation = 0;
  if (quotation.transportation) {
    transportation = quotation.transportation
  }
  return transportation
}

export const setQuotationTransportation = (quotation, value) => ({
  ...quotation,
  transportation: parseFloat(value),
});

export const getQuotationGrossValue = (quotation) => {
  var total = 0;
  total += getQuotationShutterTotal(quotation)
  total += getQuotationSectorsTotal(quotation)
  total += getQuotationBoxesTotal(quotation)
  total += getQuotationBoxSidesTotal(quotation)
  total += getQuotationTracksTotal(quotation)
  total += getQuotationMotorsTotal(quotation)
  total += getQuotationBlindsTotal(quotation)
  total += getQuotationAccessoriesTotal(quotation)
  return total;
}

export const setQuotationDiscountPercentage = (quotation, value) => ({
  ...quotation,
  discount: parseFloat(value),
});

export const setQuotationVATPercentage = (quotation, value) => ({
  ...quotation,
  vat: parseFloat(value),
});


export const getQuotationDiscountPercentage = (quotation) => {
  return quotation.discount ? quotation.discount : 0;
};

export const getQuotationVATPercentage = (quotation) => {
  return quotation.vat ? quotation.vat : 0;
};

export const getQuotationNote = (quotation) => {
  return quotation.note ? quotation.note : '';
}

export const getQuotationDiscountValue = (quotation) => {
  var gross = getQuotationGrossValue(quotation)
  var discountValue = gross * (quotation.discount / 100)

  return discountValue
}

export const getQuotationVATValue = (quotation) => {
  let vatValue = getQuotationGrossValue(quotation);
  vatValue -= getQuotationDiscountValue(quotation);
  vatValue *= (quotation?.vat / 100) || 0;
  return vatValue;
};

export const getQuotationNetValue = (quotation) => {
  var total = 0;
  if (quotation) {
    total += getQuotationGrossValue(quotation);
    total -= getQuotationDiscountValue(quotation);
    total += getQuotationVATValue(quotation);
    total += getQuotationTranportation(quotation)
  }
  return total;
}

export const getQuotationAfterDiscountValue = (quotation) => {
  var total = getQuotationGrossValue(quotation);
  total -= ((total * quotation.discount) / 100)
  return total;
}

export const quotationValid = (quotation) => {
  var result = {
    header: 'Success',
    message: 'Quotation valid',
    success: true
  }
  // shutter valiadtion
  if (quotation?.shutter) {
    return isShutterQuotationValid(quotation)
  }

  // accessories valiadtion
  if (quotation?.accessories) {
    return isAccessoriesQuotationValid(quotation)
  }

  return result
}

/*==============================================================*/
/*Payments                                                      */
/*==============================================================*/

export const getQuotationPayments = (quotation) => (quotation && quotation.payment) ? quotation.payment : [];

export const getInitialNewPayment = async () => {
  return {
    date: await getServerTimestampNow(),
    amount: 0,
    method: PaymentMethods[0],
    entry: 'Payment #'

  };
};

export const addQuotationPayment = (quotation, newPayment) => {
  const updatedQuotation = { ...quotation };
  if (!updatedQuotation.payment) {
    updatedQuotation.payment = [];
  }
  updatedQuotation.payment.push(newPayment);
  return updatedQuotation;
};

export const reOrderQuotationElement = (quotation, arrayName, fromIndex, toIndex) => {
  const updatedArray = [...quotation[arrayName]];
  const [movedItem] = updatedArray.splice(fromIndex, 1);
  updatedArray.splice(toIndex, 0, movedItem);
  return { ...quotation, [arrayName]: updatedArray };
};

export const deleteQuotationPayment = (quotation, index) => {
  const updatedQuotation = { ...quotation };
  updatedQuotation.payment.splice(index, 1);
  return updatedQuotation;
};

export const getQuotationPaymentsTotal = (quotation) => {
  return quotation.payment ? quotation.payment.reduce((acc, curr) => acc + parseFloat(curr.amount), 0) : 0;
};
export const getQuotationRemainingAmount = (quotation) => {
  return getQuotationNetValue(quotation) - (
    Array.isArray(quotation.payment)
      ? quotation.payment.reduce((acc, curr) => {
        const amount = parseFloat(curr.amount);
        return acc + (isNaN(amount) ? 0 : amount); // Handle NaN case
      }, 0)
      : 0
  );
};

export const getQuotationAdjustmentsAmount = (quotation) => {
  let totalAdjustmentValue = 0;

  if (Array.isArray(quotation.payment) && quotation.payment.length > 0) {
    for (let i = 0; i < quotation.payment.length; i++) {
      const entry = quotation.payment[i].entry;

      if (entry && (entry.includes('Adjustment') || entry.includes('adjustment'))) {
        const amount = parseFloat(quotation.payment[i].amount);
        totalAdjustmentValue += isNaN(amount) ? 0 : amount;
      }
    }
  }

  return totalAdjustmentValue;
};

/*==============================================================*/
/* Cost                                                         */
/*==============================================================*/

export const changeCostLosses = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...(quotation.cost || {}),
      losses: parseFloat(value),
    },
  };
};

export const getLossesCostPercentage = (quotation) => {
  var lossessPercentage = 0;
  if (quotation.cost) {
    if (quotation.cost.losses) {
      lossessPercentage = quotation.cost.losses
    }
  }
  return lossessPercentage
}

export const getLossessCostValue = (quotation) => {
  var value = 0;

  if (quotation.cost) {
    if (quotation.cost.losses) {
      value = (quotation.cost.losses * getCostShutterTotal(quotation)) / 100
    }
  }
  return value
}

export const getCostSubtotalValue = (quotation) => {
  var total = 0;
  total += getCostShutterTotal(quotation)
  total += getLossessCostValue(quotation)
  return total
}

export const getTotalCostEstimation = (order) => {
  var total = 0;
  total += getCostSubtotalValue(order.quotation)
  total += getCostSectorTotalPrice(order.quotation)
  total += getCostBoxesTotal(order.quotation)
  total += getCostBoxSidesTotal(order.quotation)
  total += getCostTracksTotal(order.quotation)
  total += getCostMotorsTotal(order.quotation)
  total += getCostAccessoryTotal(order.quotation)
  total += getCostBlindsTotal(order.quotation)
  return total
}

export const addExpenceItem = (quotation) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      otherExpences: [
        ...(quotation.cost && quotation.cost.otherExpences ? quotation.cost.otherExpences : []),
        {
          name: null,
          value: 0,
        },
      ],
    },
  };
};


export const removeExpenceItem = (quotation, index) => {
  const updatedQuotation = { ...quotation };

  if (index > -1) {
    updatedQuotation.cost.otherExpences.splice(index, 1);

    if (updatedQuotation.cost.otherExpences.length === 0) {
      delete updatedQuotation.cost.otherExpences;
    }
  }
  return updatedQuotation;
};


export const getOtherExpencesTotal = (quotation) => {
  var total = 0;

  if (quotation.cost) {
    if (quotation.cost.otherExpences) {
      for (var i = 0; i < quotation.cost.otherExpences.length; i++) {
        total += quotation.cost.otherExpences[i].value
      }
    }
  }


  return total
}

export const getSalesComission = (quotation) => {
  var commission = 0;

  if (quotation.cost) {
    if (quotation.cost.commission) {
      commission = quotation.cost.commission
    }
  }
  return commission
}

export const setSalesCommission = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      commission: parseFloat(value),
    },
  };
};

export const getCostTranportation = (quotation) => {
  return quotation?.cost?.transportation || 0;
}

export const setCostTransportation = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      transportation: parseFloat(value),
    },
  };
};

export const getCostTotalProducts = (quotation) => {
  return quotation?.cost?.totalProducts || 0;
}

export const setCostTotalProducts = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      totalProducts: parseFloat(value),
    },
  };
};

export const getExpencesTotal = (quotation) => {
  var total = 0;

  if (quotation.cost) {
    total += getSalesComission(quotation)
    total += getCostTranportation(quotation)
    total += getLaborTotal(quotation)
    if (quotation.cost.otherExpences) {
      total += getOtherExpencesTotal(quotation)
    }
  }
  return total
}

export const getProfit = (quotation) => {
  var value = getQuotationAfterDiscountValue(quotation) - getCostTotalProducts(quotation)
  return value;
}

export const getProfitAfterExpences = (quotation) => {
  var value = getProfit(quotation) - getExpencesTotal(quotation)
  return value
}

export const getProfitAfterTax = (quotation) => {
  var value = getProfitAfterExpences(quotation) - getTaxValue(quotation)
  return value
}

export const getInsuranceValue = (quotation) => {
  return 0;
}

export const ProfitAfterInsurance = (quotation) => {
  var value = getProfitAfterTax(quotation) - getInsuranceValue(quotation);
  return value;
}

export const getOnePercentOfFinalCost = (quotation) => {
  var value = ProfitAfterInsurance(quotation) * 0.01;
  return value;
}

export const getFinalProfit = (quotation) => {
  var value = ProfitAfterInsurance(quotation) - getOnePercentOfFinalCost(quotation)
  return value
}

export const getTaxValue = (quotation) => {
  var tax = 0;
  var temp = quotation

  if (temp.cost) {
    if (temp.cost.tax) {
      tax = temp.cost.tax
    }
  }

  return tax
}

export const setTaxValue = (quotation, value) => {
  return {
    ...quotation,
    cost: {
      ...quotation.cost,
      tax: parseFloat(value),
    },
  };
};

export const costValid = (quotation) => {
  var i = 0;
  var result = {
    header: 'Success',
    message: 'Cost Calculation Valid',
    success: true
  }

  // shutter validation
  if (quotation.shutter) {
    for (i = 0; i < quotation.shutter.length; i++) {
      if (!quotation.shutter[i].box.size) {
        result.header = "Missing Data";
        result.message = "Select Box size for Shutter table row " + (i + 1);
        result.success = false
        return result;
      }

      if (!quotation.shutter[i].boxSide) {
        result.header = "Missing Data";
        result.message = "Select Box Side for Shutter table row " + (i + 1);
        result.success = false
        return result;
      }

      if (!quotation.shutter[i].track) {
        result.header = "Missing Data";
        result.message = "Select Track for Shutter table row " + (i + 1);
        result.success = false
        return result;
      }
    }

  }


  return result
  // total validation
}

/*==============================================================*/
/* Job Order                                                    */
/*==============================================================*/

export const jobOrderValid = (quotation) => {
  var i = 0;
  var result = {
    header: 'Success',
    message: 'Job Order Valid',
    success: true
  }

  for (i = 0; i < quotation?.shutter?.length; i++) {
    if (!quotation.shutter[i].electricityDirection) {
      result.header = "Error";
      result.message = "Select electricity direction for Shutter table row " + (i + 1);
      result.success = false
      return result;
    }

    if (!quotation.shutter[i].installationMethod) {
      result.header = "Error";
      result.message = "Select installation method for Shutter table row " + (i + 1);
      result.success = false
      return result;
    }

    if (!quotation.shutter[i].tracksType) {
      result.header = "Error";
      result.message = "Select track type for Shutter table row " + (i + 1);
      result.success = false
      return result;
    }
  }

  return result
}

export const getJobOrderOverallNote = (quotation) => {
  var note = ''
  if (quotation.jobOrderNote) {
    note = quotation.jobOrderNote
  }

  return note
}

export const setJobOrderOverallNote = (quotation, value) => {
  return {
    ...quotation,
    jobOrderNote: value,
  };
};
